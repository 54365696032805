import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  IconButton
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const NewWithdrawal = ({ open, handleClose }) => {
  const [medicamento, setMedicamento] = useState("");
  const [observacion, setObservacion] = useState(
    "El paciente presenta un estado de salud estable, sin síntomas agudos."
  );
  const [retirosRestantes, setRetirosRestantes] = useState(2); // Por ejemplo, 3 retiros restantes
  const [fotoReceta, setFotoReceta] = useState(null);
  const [fotoPreview, setFotoPreview] = useState(null);

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    setFotoReceta(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFotoPreview(null);
    }
  };

  const handleSubmit = () => {
    // Aquí puedes manejar el envío del nuevo retiro
    console.log("Nuevo retiro:", medicamento, fotoReceta);
    // Luego de enviar, cerrar el modal
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Nuevo Retiro
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1"> Nombre:  Juan Perez </Typography>
        <Typography variant="body1"> DNI: 123 </Typography>

        <Box display="flex" flexDirection="column" gap={2}>
          <InputLabel>Foto de la receta</InputLabel>
          <Button variant="contained" component="label">
            Cargar foto
            <input type="file" hidden onChange={handleFotoChange} />
          </Button>
          {fotoReceta && (
            <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {fotoPreview && (
                <img
                  src={fotoPreview}
                  alt="Vista previa"
                  style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
              )}
              <Typography variant="body2" color="textSecondary">
                {fotoReceta.name}
              </Typography>
            </Box>
          )}

          <TextField
            label="Nombre del Medicamento"
            value={medicamento}
            onChange={(e) => setMedicamento(e.target.value)}
            fullWidth
          />

          <TextField
            label="Observación"
            value={observacion}
            disabled
            multiline
            rows={3}
            fullWidth
          />

          <Typography variant="body2" color={"red"}>
            Retiros restantes: {retirosRestantes}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirmar Retiro
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewWithdrawal;
