import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import moment from "moment-timezone";
import Header from "./HeaderDate";
import SearchBar from "./SearchBar";
import PatientNotFoundAlert from "./PatientNotFoundAlert";
import NuevoRetiro from "./NewWithdrawal"; // Asegúrate de que la ruta sea correcta

const MainPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dateZ] = useState(new Date());
  const [openAlert, setOpenAlert] = useState(false);
  const [openNuevoRetiro, setOpenNuevoRetiro] = useState(false); // Estado para controlar el modal
  const [patientData, setPatientData] = useState({
    name: "",
    lastName: "",
    dni: "",
    admissionDate: "",
    pendingWithdrawals: 0,
    observations: [],
    healthInsurance: "",
    branch: "01",
    withdrawals: [],
  });
  const [searchQuery, setSearchQuery] = useState("");

  const date = moment(dateZ)
    .tz("America/Argentina/Buenos_Aires")
    .format("YYYY-MM-DD");

  useEffect(() => {
    const fetchPatientData = async () => {
      if (searchQuery === "123") {
        const fetchedData = {
          name: "Juan",
          lastName: "Pérez",
          dni: searchQuery,
          admissionDate: "2024-08-01",
          pendingWithdrawals: 2,
          observations: [
            "El paciente presenta un estado de salud estable, sin síntomas agudos.",
          ],
          healthInsurance: "Obra Social XYZ",
          branch: "01",
          withdrawals: [
            { medication: "Paracetamol", date: "2024-08-10" },
            { medication: "Ibuprofeno", date: "2024-08-12" },
          ],
        };

        setPatientData(fetchedData);
      } else if (searchQuery === "1234") {
        setOpenAlert(true);
      }
    };

    fetchPatientData();
  }, [searchQuery]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSearch = (dni) => {
    setSearchQuery(dni);
    console.log("search", dni);
  };

  const handleClickOpenNuevoRetiro = () => {
    setOpenNuevoRetiro(true);
  };

  const handleCloseNuevoRetiro = () => {
    setOpenNuevoRetiro(false);
  };

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={1000}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          bgcolor: "primary.main",
          height: "85vh",
          overflow: "hidden",
          flexGrow: 1,
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
            padding: 2,
            pt: 0,
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 3, pr: 3 }}
          >
            <Grid item xs={6}>
              <Header />
            </Grid>
            <Grid item xs={5} key="search-bar">
              <SearchBar onSearch={handleSearch} />
              123, luego Enter, para ver la búsqueda existente <br /> 1234,
              luego Enter, para ver cómo actúa el sistema si el DNI no existe
            </Grid>
          </Grid>

          <Card
            variant="outlined"
            sx={{ width: "100%", marginTop: 2, position: "relative" }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5">{`${patientData.name || "-"} ${
                  patientData.lastName || "-"
                }`}</Typography>
                <Typography color="textSecondary">
                  DNI: {patientData.dni || "-"}
                </Typography>
                <Typography color="textSecondary">
                  Fecha de Alta:{" "}
                  {moment(patientData.admissionDate).format("DD/MM/YYYY") ||
                    "-"}
                </Typography>
                <Typography color="textSecondary">
                  Obra Social: {patientData.healthInsurance || "-"}
                </Typography>
                <Typography color="textSecondary">
                  Sucursal de Alta: {patientData.branch || "-"}
                </Typography>
              </Box>
              <Typography
                variant="h4"
                color="error"
                sx={{ textAlign: "right" }}
              >
                {patientData.pendingWithdrawals} Retiros Pendientes
              </Typography>
            </CardContent>
          </Card>

          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={6}>
              <Card variant="outlined" sx={{ height: "200px" }}>
                <CardContent>
                  <Typography variant="h6">Observaciones</Typography>
                  {patientData.observations.length > 0 ? (
                    patientData.observations.map((obs, index) => (
                      <Typography key={index} variant="body1">
                        - {obs}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body1">
                      - - - - - - - - - - -
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined" sx={{ height: "200px" }}>
                <CardContent>
                  <Typography variant="h6">Retiros</Typography>
                  {patientData.withdrawals.length > 0 ? (
                    patientData.withdrawals.map((withdrawal, index) => (
                      <Typography key={index} variant="body2">
                        {`${withdrawal.medication} - ${moment(
                          withdrawal.date
                        ).format("DD/MM/YYYY")}`}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body1">
                      - - - - - - - - - - -
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!patientData.name}
                onClick={handleClickOpenNuevoRetiro}
              >
                Nuevo Retiro
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <PatientNotFoundAlert
        open={openAlert}
        onClose={handleCloseAlert}
        dni={searchQuery}
      />
      <NuevoRetiro
        open={openNuevoRetiro}
        handleClose={handleCloseNuevoRetiro}
      />
    </SnackbarProvider>
  );
};

export default MainPage;
