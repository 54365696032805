import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

// Datos de ejemplo
const patients = [
  {
    id: 1,
    name: "Juan Pérez",
    birthDate: "12/05/1980",
    insurance: "PAMI",
  },
  {
    id: 2,
    name: "Ana López",
    birthDate: "23/07/1992",
    insurance: "PAMI",
  },
  // Agrega más pacientes según sea necesario
];

export default function PatientsTable() {
  const handleEdit = (id) => {
    console.log(`Editar paciente con id ${id}`);
  };

  const handleView = (id) => {
    console.log(`Ver observaciones del paciente con id ${id}`);
  };

  const handleWithdraw = (id) => {
    console.log(`Registrar retiro del paciente con id ${id}`);
  };

  const handleDelete = (id) => {
    console.log(`Eliminar paciente con id ${id}`);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Pacientes
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre y Apellido</TableCell>
              <TableCell>Fecha de Nacimiento</TableCell>
              <TableCell>Obra Social</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient) => (
              <TableRow key={patient.id}>
                <TableCell>{patient.name}</TableCell>
                <TableCell>{patient.birthDate}</TableCell>
                <TableCell>{patient.insurance}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Editar">
                    <IconButton
                      color="primary"
                      onClick={() => handleEdit(patient.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Observaciones">
                    <IconButton
                      color="info"
                      onClick={() => handleView(patient.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Retiros">
                    <IconButton
                      color="success"
                      onClick={() => handleWithdraw(patient.id)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Borrar">
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(patient.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
