import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const formatDNI = (value) => {
  const cleanedValue = value.replace(/\D/g, ""); // Remover todo excepto números
  if (cleanedValue.length > 10) return cleanedValue.slice(0, 8);
  return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const SearchBar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    const rawValue = event.target.value;
    // Eliminar puntos al ingresar
    const valueWithoutDots = rawValue.replace(/\./g, "");
    // Eliminar ceros al principio al setear el estado
    const formattedValue = valueWithoutDots.replace(/^0+/, "");
    setSearchQuery(formatDNI(formattedValue));
  };

  const handleClear = () => {
    setSearchQuery("");
    onSearch(searchQuery); // Llama a la función onSearch con una cadena vacía
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Pasar el DNI sin puntos y sin ceros al principio
    onSearch(searchQuery.replace(/\./g, ""));
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: "flex", alignItems: "center" }}
    >
      <TextField
        value={searchQuery}
        onChange={handleSearchChange}
        variant="outlined"
        placeholder="Buscar DNI..."
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={searchQuery ? handleClear : handleSubmit}
                edge="end"
              >
                {searchQuery ? (
                  <ClearIcon sx={{ color: "#258786" }} />
                ) : (
                  <SearchIcon sx={{ color: "#258786" }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginRight: 8 }}
      />
    </form>
  );
};

export default SearchBar;
