import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ToExpireTable = () => {
  // Datos de ejemplo
  const patients = [
    {
      name: "Juan Pérez",
      withdrawals: 3,
      expirationDate: "15/08/2024",
    },
    {
      name: "Ana Gómez",
      withdrawals: 1,
      expirationDate: "20/08/2024",
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Retiros a Vencer
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre del Paciente</TableCell>
              <TableCell>Retiros Restantes</TableCell>
              <TableCell>Vencimiento</TableCell>
              <TableCell>Contacto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient, index) => (
              <TableRow key={index}>
                <TableCell>{patient.name}</TableCell>
                <TableCell>{patient.withdrawals}</TableCell>
                <TableCell>{patient.expirationDate}</TableCell>
                <TableCell>
                  <Tooltip title="Enviar WhatsApp">
                    <WhatsAppIcon sx={{ color: "green", cursor: "pointer" }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ToExpireTable;
