import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { addEmployee, checkDniUnique } from "../services";

const AddPatient = ({ dniProp }) => {
  const [dni, setDni] = useState(dniProp || "");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [dniError, setDniError] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  // Refs para los campos
  const dniInputRef = useRef(null);
  const nombreInputRef = useRef(null);

  useEffect(() => {
    if (dniProp) {
      setDni(formatDni(dniProp));
      nombreInputRef.current.focus(); // Hacer focus en Nombre si hay dniProp
    } else {
      dniInputRef.current.focus(); // Hacer focus en DNI si no hay dniProp
    }
  }, [dniProp]);

  const formatDni = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.length > 10) return cleanedValue.slice(0, 8);
    return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleDniChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, "");
    setDni(formatDni(value));
    setDniError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const isUnique = await checkDniUnique(dni.replace(/\./g, ""));
      if (!isUnique) {
        setDniError("El DNI ya está en uso.");
        return;
      }
    } catch (error) {
      enqueueSnackbar("Error al verificar el DNI", {
        autoHideDuration: 800,
        variant: "error",
      });
      return;
    }

    const paciente = {
      dni: dni.replace(/\./g, ""),
      nombre,
      apellido,
      sucursal,
      observaciones,
    };

    try {
      await addEmployee(paciente);
      enqueueSnackbar("Paciente añadido correctamente", {
        autoHideDuration: 800,
        variant: "success",
      });

      setDni("");
      setNombre("");
      setApellido("");
      setSucursal("");
      setObservaciones("");
      setDniError("");
      dniInputRef.current.focus(); // Hacer focus en DNI después de enviar
    } catch (error) {
      enqueueSnackbar("Error al añadir paciente", {
        autoHideDuration: 800,
        variant: "error",
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", padding: 2 }}>
      <Typography variant="h5">Añadir Paciente</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="DNI"
          value={dni}
          onChange={handleDniChange}
          required
          fullWidth
          margin="normal"
          error={!!dniError}
          helperText={dniError}
          inputRef={dniInputRef} // Ref para el campo DNI
        />
        <TextField
          label="Nombre"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          required
          fullWidth
          margin="normal"
          inputRef={nombreInputRef} // Ref para el campo Nombre
        />
        <TextField
          label="Apellido"
          value={apellido}
          onChange={(e) => setApellido(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Sucursal</InputLabel>
          <Select
            value={sucursal}
            onChange={(e) => setSucursal(e.target.value)}
            required
          >
            <MenuItem value="01">Farmacia 01</MenuItem>
            <MenuItem value="02">Farmacia 02</MenuItem>
            <MenuItem value="03">Farmacia 03</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Observaciones"
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
        >
          Añadir Paciente
        </Button>
      </form>
    </Box>
  );
};

export default AddPatient;
